/**
 * @param {object} context
 */
export default {
  update(context) {
    Array.from(document.querySelectorAll(".video-js")).forEach((video) => {
      if (window.bc && context.event && context.event === "content-updated") {
        window.bc(video);
      }

      if (
        context.event &&
        ["content-updated", "bc-fetched"].includes(context.event)
      ) {
        this.addPlayListener(video);
      }
    });
  },

  /**
   * Adds BrightCove event listener to video element
   *
   * @see https://docs.brightcove.com/brightcove-player/current-release/module-videojs-videojs.html#.on
   * @param {Element} video
   */
  addPlayListener(video) {
    if (window.videojs) {
      window.videojs.on(video, "play", () => {
        if (window.digitalData) {
          let videoId = video.id;
          if (!videoId.includes("_html5_api")) {
            // video element differs when bc is already on the page vs on first load
            videoId = `${videoId}_html5_api`;
          }

          // Instatag tracking
          window.digitalData.video = {
            videoTagID: videoId,
          };
        }
      });
    }
  },
};
