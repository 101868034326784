class MobileDropdown {
  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this.toggle = element;
    this.targetId = this.toggle.getAttribute("aria-controls");
    this.target = document.getElementById(this.targetId);

    if (!this.target) return;

    this.toggle.addEventListener("click", this.clickHandler.bind(this));
  }

  /**
   * Handles the click event on the "toggle" element
   */
  clickHandler() {
    const isExpanded = this.toggle.getAttribute("aria-expanded") === "true";

    this.toggle.setAttribute("aria-expanded", (!isExpanded).toString());
    this.toggle.parentNode.classList.add("is-animated");
    setTimeout(() => {
      this.toggle.parentNode.classList.toggle("is-active");
    });
    this.target.classList.toggle("is-active");

    this.toggle.classList.toggle("is-animatedIn", !isExpanded);
    this.toggle.classList.toggle("is-animatedOut", isExpanded);
  }
}

export default MobileDropdown;
