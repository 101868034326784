import MobileDropdown from "../views/template-components/header-navigation/mobile-dropdown/mobile-dropdown.js";
import MobileDropdownItem from "../views/template-components/header-navigation/mobile-dropdown/item/item.js";
import AccordionItem from "../views/patterns/accordion/item/item.js";
import Modal from "../views/patterns/button/modal/modal.js";
import Gallery from "../views/patterns/gallery/gallery.js";
import Legal from "../views/template-components/legal/legal.js";
import MobileLegal from "../views/patterns/mobile-legal/mobile-legal.js";
import ContentFetching from "./content-fetching.js";
import FetchBrightcove from "./fetch-brightcove.js";
import Video from "./video.js";
import "./modernizr.js";

const Factory = {
  ".js-MobileDropdown": MobileDropdown,
  ".js-MobileDropdownItem": MobileDropdownItem,
  ".js-Legal": Legal,
  ".js-MobileLegal": MobileLegal,
};

class App {
  /**
   *
   */
  constructor() {
    if (
      !("querySelector" in document) ||
      !("addEventListener" in window) ||
      !document.documentElement.classList
    ) {
      return;
    }

    this.contentWrapper = document.querySelector(".DefaultPage-contentWrapper");

    const footerNavigation = document.querySelector(".FooterNavigation");
    App.removeInitialFooterAnimation(footerNavigation);

    this.bind(document);

    const fetchBC = new FetchBrightcove();
    fetchBC.init();
    fetchBC.subscribe(Video);

    let contentFetchingObj;

    window.Modernizr.on("webp", (result) => {
      if (result) {
        contentFetchingObj = new ContentFetching(
          document.documentElement,
          true
        );
        contentFetchingObj.subscribe(Video);
        contentFetchingObj.subscribe(fetchBC);
        contentFetchingObj.subscribe(this);
      } else {
        App.isWebpSupported((support) => {
          contentFetchingObj = new ContentFetching(
            document.documentElement,
            support
          );
          contentFetchingObj.subscribe(Video);
          contentFetchingObj.subscribe(fetchBC);
          contentFetchingObj.subscribe(this);
        });
      }
    });

    window.addEventListener("resize", this.onResize.bind(this));
    window.addEventListener("orientationchange", this.onResize.bind(this));
    this.onResize();
    // eslint-disable-next-line
    new AccordionItem();

    const modal = new Modal();
    let activeVideoId = null;

    // Plays the video on modal open on mobile so it goes fullscreen
    modal.onOpen(function cb() {
      const gallery = this.modal.querySelector(".js-Gallery");
      if (gallery) {
        // eslint-disable-next-line
        new Gallery(gallery);
      }

      activeVideoId = this.triggerElement
        ? this.triggerElement.dataset.videoId
        : null;

      if (window.videojs && activeVideoId) {
        window.videojs
          .getPlayer(document.getElementById(activeVideoId))
          .ready(function pauseVideo() {
            this.play();
          });
      }
    });

    // Pauses the video on modal close
    modal.onClose(function cb() {
      if (window.videojs && activeVideoId) {
        window.videojs
          .getPlayer(document.getElementById(activeVideoId))
          .ready(function pauseVideo() {
            this.pause();
          });
      }
      activeVideoId = null;
    });
  }

  /**
   * @param {HTMLDocument} context
   */
  bind(context) {
    let el;

    if (typeof context === "string") {
      el = document.querySelector(context);
    } else if (typeof context[0] !== "undefined") {
      [el] = context;
    } else {
      el = context;
    }

    Object.keys(Factory).forEach((key) => {
      const mangledKey = key.toLowerCase().replace(".js-", "");

      if (!this[mangledKey]) {
        this[mangledKey] = [];
      }

      Array.prototype.push.apply(
        this[mangledKey],
        [].slice
          .call(el.querySelectorAll(key))
          .map((element) => new Factory[key](element))
      );
    });
  }

  /**
   * @memberof App
   * @returns {void}
   */
  onResize() {
    clearTimeout(this.resizeTimeout);

    this.resizeTimeout = setTimeout(() => {
      App.setDocHeight();
      this.update();
    }, 250);
  }

  /**
   * After page load, window resizing and content updates
   * set or remove tabindex from the content element,
   * based on if it's scrollable or not, to allow scrolling via arrow keys.
   * NOTE: We do not need to set tabindex="0" when e.g. opening an accordion item,
   * as the focus is inside the content then anyway, so scrolling via arrow
   * keys would be possible.
   *
   * @memberof App
   * @param {object} context
   * @returns {void}
   */
  update(context) {
    if (this.contentWrapper) {
      const content = this.contentWrapper.querySelector(".DefaultPage-content");

      if (content) {
        const parent = content.parentElement;

        if (content.scrollHeight > parent.clientHeight) {
          content.setAttribute("tabindex", "0");
        } else {
          content.removeAttribute("tabindex");
        }
      }
    }

    // Tracks page view with Instatag
    if (context && context.event && context.event === "content-updated") {
      if (window.WSC) {
        const event =
          window.WSC.modules.instatag.eventRegister.getEvent("event1");
        const documentTitle = document.querySelector("title").innerText;
        event.getEvar("eVar8").value = documentTitle;
        event.setOption("callSatellite", true);
        event.fire();
      }

      App.updateNavigationArrowsTracking();
    }
  }

  /**
   * Updates the data-it-button tracking attribute with the label of the button
   *
   * @memberof App
   * @returns {void}
   */
  static updateNavigationArrowsTracking() {
    const elements = {
      arrows: {
        prev: {
          link: document.querySelector(".NavigationArrows-prev"),
          label: document.querySelector(
            ".NavigationArrows-prev .NavigationArrows-label"
          ),
        },
        next: {
          link: document.querySelector(".NavigationArrows-next"),
          label: document.querySelector(
            ".NavigationArrows-next .NavigationArrows-label"
          ),
        },
      },
    };

    elements.arrows.prev.link.setAttribute(
      "data-it-button",
      elements.arrows.prev.label.textContent
    );

    elements.arrows.next.link.setAttribute(
      "data-it-button",
      elements.arrows.next.label.textContent
    );
  }

  /**
   * @returns {void}
   */
  static setDocHeight() {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 1000}rem`
    );
  }

  /**
   * @param {Element} footerNavigation
   * @returns {void}
   */
  static removeInitialFooterAnimation(footerNavigation) {
    if (footerNavigation) {
      footerNavigation.addEventListener("animationend", (e) => {
        e.stopPropagation();

        const wrapper = document.querySelector(".DefaultPage-footerNavigation");

        if (wrapper) {
          wrapper.classList.remove("is-animated");
        }
      });
    }
  }

  /**
   * @param {Function} callback
   * @returns {void}
   */
  static isWebpSupported(callback) {
    const img = new Image();

    img.onload = () => {
      const result = img.width > 0 && img.height > 0;
      callback(result);
    };

    img.onerror = () => {
      callback(false);
    };

    img.src =
      "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
  }
}

document.addEventListener("DOMContentLoaded", () => new App());
