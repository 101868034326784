import Swipe from "./swipe.js";

class Gallery {
  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this.mainPictureSelector = ".js-Gallery-picture";
    this.listSelector = ".js-Gallery-list";
    this.itemSelector = ".js-Gallery-listItem";
    this.imageButtonSelector = ".js-Gallery-imageButton";
    this.navButtonsSelector = ".js-Gallery-button";

    this.gallery = element;
    this.allPictures = Array.from(
      this.gallery.querySelectorAll(this.mainPictureSelector)
    );
    this.carouselItems = Array.from(
      this.gallery.querySelectorAll(this.itemSelector)
    );
    if (!this.carouselItems.length) return;
    this.navButtons = Array.from(
      document.querySelectorAll(this.navButtonsSelector)
    );

    this.currentIndex = 0;
    this.currentTransform = 0;

    this.carouselItems.forEach((el) => {
      el.addEventListener("click", this.selectImageHandler.bind(this));
    });

    this.navButtons.forEach((el) => {
      el.addEventListener("click", this.navClickHandler.bind(this));
    });

    const swiper = new Swipe(element);
    swiper.onLeft(this.goToNextImage.bind(this));
    swiper.onRight(this.goToPreviousImage.bind(this));

    window.addEventListener("keydown", this.keydownHandler.bind(this));
    window.addEventListener("resize", () => {
      this.selectImage(this.currentIndex);
      this.slideToImage();
    });
  }

  /**
   *
   */
  updateCurrentTransform() {
    // 2 here accounts for the border widths
    this.currentTransform =
      this.currentIndex *
        this.carouselItems[this.currentIndex].offsetWidth *
        -1 +
      2;
  }

  /**
   * Updates the elements transform to create a slide effect (tied to transition CSS property)
   */
  slideToImage() {
    this.navButtons.forEach((el) => el.setAttribute("disabled", "true"));

    // Disables buttons while transition is still in progress
    const transitionEndHandler = () => {
      this.navButtons.forEach((el) => el.removeAttribute("disabled"));

      this.carouselItems[0].removeEventListener(
        "transitionend",
        transitionEndHandler
      );
    };
    this.carouselItems[0].addEventListener(
      "transitionend",
      transitionEndHandler
    );

    this.carouselItems.forEach((el) => {
      // eslint-disable-next-line
      el.style.transform = `translateX(${((this.currentTransform)/ 10)}rem)`;
    });
  }

  /**
   * Returns the list to first element
   */
  resetSelectorsCarousel() {
    this.selectImage(0);
    this.currentTransform = 0;

    this.carouselItems.forEach((el) => {
      // eslint-disable-next-line
      el.style.transform = `translateX(0)`;
    });
  }

  /**
   * Handles clikcs on the iamge buttons
   *
   * @param {MouseEvent} e
   */
  selectImageHandler({ target }) {
    const listItem = target.closest(this.itemSelector);
    const { index } = listItem.dataset;

    this.selectImage(parseInt(index, 10));
  }

  /**
   * @param {KeyboardEvent} e
   */
  keydownHandler(e) {
    const key = e.key || e.keyCode;
    if (key === "ArrowLeft" || key === 37) {
      this.goToPreviousImage();
    } else if (key === "ArrowRight" || key === 39) {
      this.goToNextImage();
    }
  }

  /**
   * Handles clicks on either of the navigation buttons
   *
   * @param {MouseEvent} e
   */
  navClickHandler({ target }) {
    const navButton = target.closest(this.navButtonsSelector);
    if (navButton.classList.contains("Gallery-previous")) {
      this.goToPreviousImage();
    } else if (navButton.classList.contains("Gallery-next")) {
      this.goToNextImage();
    }
  }

  /**
   * Checks if it's not the first image,
   * else goes to previous image in the list
   */
  goToPreviousImage() {
    if (this.currentIndex > 0) {
      this.selectImage(this.currentIndex - 1);
    } else {
      this.selectImage(this.carouselItems.length - 1);
    }
  }

  /**
   * Check if it is possible to go to the next image in the list
   * or goes back to the first image
   */
  goToNextImage() {
    if (this.currentIndex < this.carouselItems.length - 1) {
      this.selectImage(this.currentIndex + 1);
    } else {
      this.resetSelectorsCarousel();
    }
  }

  /**
   * Selects the image button and shows the respective photo
   *
   * @param {number} index
   */
  selectImage(index) {
    this.currentIndex = index;

    this.updateCurrentTransform();

    const activeItem = this.gallery.querySelector("[aria-current=true]");
    if (activeItem) {
      activeItem.removeAttribute("aria-current");
    }

    this.carouselItems[this.currentIndex].setAttribute("aria-current", true);

    this.hideAllPhotos();
    this.showPhoto(this.currentIndex);

    if (!this.isInViewableArea(this.carouselItems[this.currentIndex])) {
      this.slideToImage();
    }
  }

  /**
   * Hides all the picture elements
   */
  hideAllPhotos() {
    this.allPictures.forEach((el) => {
      el.setAttribute("hidden", true);
    });
  }

  /**
   * Shows picture with given index
   *
   * @param {number} index
   */
  showPhoto(index) {
    this.allPictures[index].removeAttribute("hidden");
  }

  /**
   * Checks if given element is entirely inside the "viewable" area of the list
   *
   * @param {HTMLElement} element
   * @returns {boolean}
   */
  isInViewableArea(element) {
    const listBounding = document
      .querySelector(this.listSelector)
      .getBoundingClientRect();
    const bounding = element.getBoundingClientRect();
    // Thumbnails have 1.6rem of padding, hence this 16
    return (
      bounding.left >= listBounding.left &&
      bounding.right - 16 <= listBounding.right
    );
  }
}

export default Gallery;
