import {
  setMaxHeight,
  removeMaxHeight,
} from "../../../../../js/setMaxHeight.js";

class MobileDropdownItem {
  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this.toggle = element;
    this.targetId = this.toggle.getAttribute("aria-controls");
    this.target = document.getElementById(this.targetId);
    this.clickAction = "click";
    this.closeAction = "click";

    if (
      this.targetId.includes("desktop") &&
      this.target.classList.contains("DesktopDropdown-nav")
    ) {
      this.clickAction = "mouseover";
      this.closeAction = "mouseout";
    }

    if (!this.target) return;

    this.toggle.addEventListener(
      this.clickAction,
      this.clickHandler.bind(this)
    );

    window.addEventListener(
      this.closeAction,
      this.closeDropdownHandler.bind(this)
    );
  }

  /**
   * Handles the click event on the "toggle" element
   */
  clickHandler() {
    const isExpanded = this.toggle.getAttribute("aria-expanded") === "true";

    if (!isExpanded) {
      setMaxHeight(this.target, ".HeaderNavigation-listMobileWrapper");
    } else {
      removeMaxHeight(this.target);
    }

    this.toggle.setAttribute("aria-expanded", (!isExpanded).toString());
    this.toggle.parentNode.classList.toggle("is-active");
    this.target.classList.toggle("is-active");
  }

  /**
   * Handles the event that should close the target element
   * e.g. Clicks outside the target and clicks on links
   *
   * @param {MouseEvent} e
   */
  closeDropdownHandler(e) {
    const { target } = e;

    if (
      !this.target.contains(target) &&
      !this.toggle.parentElement.contains(target)
    ) {
      this.toggle.setAttribute("aria-expanded", "false");
      this.toggle.parentNode.classList.remove("is-active");
      this.target.classList.remove("is-active");
      removeMaxHeight(this.target);
    }
  }
}

export default MobileDropdownItem;
