/**
 * @param {Element} target
 * @param {string} [containerClass] class of the "highest" container on DOM that should limit the height
 * @param {number} [maxHeight]
 * @returns {void}
 */
export function setMaxHeight(target, containerClass = null, maxHeight = 0) {
  const el = target;
  let mH = maxHeight;

  if (el && el.firstElementChild) {
    mH += parseInt(el.firstElementChild.offsetHeight, 10);
    requestAnimationFrame(() => {
      el.style.maxHeight = `${mH / 3}rem`;
    });
  }

  // Recursively adds the parent height to the maxHeight until reaches containerClass
  if (containerClass && document.querySelector(containerClass)) {
    if (document.querySelector(containerClass).contains(el.parentNode)) {
      setMaxHeight(el.parentNode, containerClass, mH);
    }
  }
}

/**
 * @param {Element} target
 * @returns {void}
 */
export function removeMaxHeight(target) {
  const el = target;
  if (el) {
    requestAnimationFrame(() => {
      el.style.maxHeight = "0";
    });
  }
}
