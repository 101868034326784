import { setMaxHeight, removeMaxHeight } from "../../../../js/setMaxHeight.js";

/**
 * Closes all accordion items in the document except the current one
 *
 * @param {MouseEvent} e
 */
function closeAllItems(e) {
  const accordionItems = Array.from(
    document.querySelectorAll(".js-AccordionItem")
  );

  accordionItems.forEach((item) => {
    if (e.target.id !== item.id) {
      item.setAttribute("aria-expanded", "false");
      item.parentElement.classList.remove("is-expanded");
      const targetId = item.getAttribute("aria-controls");
      const target = document.getElementById(targetId);
      removeMaxHeight(target);
    }
  });
}

class AccordionItem {
  /**
   *
   */
  constructor() {
    this.bottomSpacing = 16;
    this.selector = "js-AccordionItem";

    document.body.addEventListener("click", this.clickHandler.bind(this));
    document.body.addEventListener("click", closeAllItems);
  }

  /**
   * Binds target and toggle elements to class properties
   *
   * @param {Element} toggle
   */
  bind(toggle) {
    this.toggle = toggle;
    this.targetId = this.toggle.getAttribute("aria-controls");
    this.target = document.getElementById(this.targetId);
  }

  /**
   * @param {MouseEvent} e
   */
  clickHandler({ target }) {
    /**
     * Always query for all accordion items in the DOM
     * to make sure dynamically added content is also found
     */
    const accordionItems = Array.from(
      document.querySelectorAll(`.${this.selector}`)
    );
    const item = accordionItems.find(
      (acc) => acc.isEqualNode(target) || acc.contains(target)
    );

    if (item) {
      this.bind(item);
      this.toggleTarget();
    }
  }

  /**
   * Opens/closes the accordion content by toggling the aria-expanded attribute
   */
  toggleTarget() {
    const isExpanded = this.toggle.getAttribute("aria-expanded") === "true";

    this.toggle.setAttribute("aria-expanded", (!isExpanded).toString());
    this.toggle.parentElement.classList.toggle("is-expanded");

    if (!isExpanded) {
      this.target.addEventListener(
        "transitionend",
        this.transitionEndHandler.bind(this)
      );
      setMaxHeight(this.target);
    } else {
      removeMaxHeight(this.target);
    }
  }

  /**
   * Scrolls smoothly to the bottom of the target element if the bottom
   * part of it is hidden under the container view
   *
   * @param {number} scrollValue
   */
  scrollToTargetBottom(scrollValue) {
    if (this.container.scrollTop < scrollValue) {
      requestAnimationFrame(() => {
        this.container.scrollTop += Math.max(
          1,
          (scrollValue - this.container.scrollTop) * 0.33
        );
        this.scrollToTargetBottom(scrollValue);
      });
    }
  }

  /**
   * Jumps to the top of the target element if the top part of it is
   * hidden under the container view
   *
   * @param {number} scrollValue
   */
  scrollToTargetTop(scrollValue) {
    requestAnimationFrame(() => {
      this.container.scrollTop = scrollValue;
    });
  }

  /**
   * Checks if needs to scroll to target after the transition has ended
   */
  transitionEndHandler() {
    this.container = document.querySelector(".DefaultPage-content");

    const targetRect = this.target.parentElement.getBoundingClientRect();
    const containerRect = this.container.getBoundingClientRect();

    const isItemTopPartiallyHidden = targetRect.top < containerRect.top;
    const isItemBottomPartiallyHidden =
      targetRect.bottom > containerRect.bottom;
    const isItemHeightLargerThanContainerHeight =
      this.target.parentElement.offsetHeight > this.container.offsetHeight;

    if (isItemTopPartiallyHidden) {
      this.scrollToTargetTop(this.target.parentElement.offsetTop);
    } else if (isItemBottomPartiallyHidden) {
      if (isItemHeightLargerThanContainerHeight) {
        if (this.container.scrollTop < this.target.parentElement.offsetTop) {
          this.scrollToTargetTop(this.target.parentElement.offsetTop);
        }
      } else {
        const newScrollTop =
          targetRect.bottom -
          containerRect.bottom +
          this.container.scrollTop +
          this.bottomSpacing;
        this.scrollToTargetBottom(newScrollTop);
      }
    }
    this.target.removeEventListener("transitionend", this.transitionEndHandler);
  }
}

export default AccordionItem;
