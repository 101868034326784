class ContentFetching {
  /**
   * @param {HTMLElement} context
   * @param {boolean} webpSupported
   */
  constructor(context, webpSupported) {
    this.isChangingPage = false;

    if (navigator.connection) {
      if (navigator.connection.downlink && navigator.connection.downlink < 1) {
        return;
      }

      if (navigator.connection.saveData === true) {
        return;
      }
    }

    if (navigator.userAgent.indexOf("Trident/7.0") > -1) {
      return;
    }

    this.subscribers = [];

    this.data = {};
    this.fetching = {};
    this.state = {};
    this.windowWidth = window.innerWidth;
    this.webpSupported = webpSupported;

    this.elements = {
      defaultPage: document.querySelector(".DefaultPage"),
      headerNavigation: document.querySelector(".HeaderNavigation"),
      navigationWrapper: document.querySelector(".HeaderNavigation-wrapper"),
      navigationMenu: document.querySelector(".HeaderNavigation-menu"),
      spinner: document.querySelector(".DefaultPage-loaderWrapper"),
      contentWrapper: document.querySelector(".DefaultPage-contentWrapper"),
      internalLinks: Array.from(document.querySelectorAll("a")).filter((a) =>
        ContentFetching.isInternalLink(a)
      ),
      arrows: {
        prev: {
          link: document.querySelector(".NavigationArrows-prev"),
          label: document.querySelector(
            ".NavigationArrows-prev .NavigationArrows-label"
          ),
        },
        next: {
          link: document.querySelector(".NavigationArrows-next"),
          label: document.querySelector(
            ".NavigationArrows-next .NavigationArrows-label"
          ),
        },
      },
    };

    context.addEventListener("click", this.onClick.bind(this));
    context.addEventListener("focus", this.onMouseover.bind(this));
    context.addEventListener("mouseover", this.onMouseover.bind(this));
    context.addEventListener("mousemove", this.onMouseover.bind(this));
    window.addEventListener("popstate", this.onPopstate.bind(this));
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });

    this.prefetchContent().then(() => {
      if (this.elements.arrows.next.link) {
        const data =
          this.data[this.elements.arrows.next.link.getAttribute("href")];

        if (data && data.background_media && data.background_media.url) {
          this.prefetchImage(data.background_media.url);
        }
      }
      if (this.elements.arrows.prev.link !== null) {
        if (
          this.elements.arrows.prev.link.getAttribute("data-entry-id") ===
          "prev-none"
        ) {
          this.elements.arrows.prev.link.classList.add(
            "NavigationArrows-disabled--prev"
          );
        } else {
          this.elements.arrows.prev.link.classList.remove(
            "NavigationArrows-disabled--prev"
          );
        }
      }
      if (this.elements.arrows.next.link !== null) {
        if (
          this.elements.arrows.next.link.getAttribute("data-entry-id") ===
          "next-none"
        ) {
          this.elements.arrows.next.link.classList.add(
            "NavigationArrows-disabled--next"
          );
        } else {
          this.elements.arrows.next.link.classList.remove(
            "NavigationArrows-disabled--next"
          );
        }
      }
    });
  }

  /**
   * @returns {void}
   */
  onPopstate() {
    this.changePage(document.location.pathname);
  }

  /**
   * @param {Event} e
   */
  onClick(e) {
    const link = ContentFetching.getLink(e.target);

    if (
      link &&
      ContentFetching.isInternalLink(link) &&
      link.getAttribute("href") !== document.location.pathname
    ) {
      this.changePage(link.getAttribute("href"), true, e);
      window.requestAnimationFrame(() => {
        this.elements.navigationWrapper.classList.remove("is-active");
        this.elements.navigationMenu.classList.remove("is-animatedIn");
        this.elements.navigationMenu.classList.add("is-animatedOut");
        this.elements.navigationMenu.setAttribute("aria-expanded", "false");
      });
    }
  }

  /**
   * @param {MouseEvent} e
   */
  onMouseover(e) {
    e.stopPropagation();

    const link = ContentFetching.getLink(e.target);

    if (link) {
      const url = link.getAttribute("href");

      this.prefetchContent(url).then(() => {
        if (
          this.data[url] &&
          this.data[url].background_media &&
          this.data[url].background_media.url
        ) {
          this.prefetchImage(this.data[url].background_media.url);
        }
      });
    }
  }

  /**
   * @param {string} url
   * @param {boolean} [updateHistory]
   * @param {Event} [e]
   * @returns {void}
   */
  changePage(url, updateHistory, e) {
    if (this.isChangingPage) return;

    this.isChangingPage = true;

    this.fetchContent(url).then((result) => {
      if (e) {
        e.preventDefault();
      }

      this.data[url] = result;
      this.state = this.data[url];

      this.replacePage();

      if (updateHistory) {
        window.history.pushState(this.data[url], this.data[url].title, url);
      }
    });
  }

  /**
   * Prefetches either a given url or all internal urls.
   * Won't prefetch them when they already have been prefetched
   * or are being prefetched right now.
   *
   * @param {string} [url]
   * @returns {Promise}
   */
  prefetchContent(url) {
    return new Promise((resolveAllFetches) => {
      const urls = [];
      const promises = [];

      if (url) {
        urls.push(url);
      } else {
        this.elements.internalLinks.forEach((a) => {
          urls.push(a.getAttribute("href"));
        });
      }

      urls.forEach((entry) => {
        if (!this.data[entry] && !this.fetching[entry]) {
          promises.push(
            new Promise((resolve) => {
              this.fetching[entry] = true;
              fetch(entry, {
                headers: {
                  Accept: "application/json",
                },
              })
                .then((res) => res.json())
                .then((res) => {
                  this.data[entry] = res;
                  this.fetching[entry] = false;
                  resolve();
                });
            })
          );
        }
      });

      Promise.all(promises).then(resolveAllFetches);
    });
  }

  /**
   * @param {string} url
   * @returns {Promise} gets resolved with the response of the fetched url
   */
  fetchContent(url) {
    return new Promise((resolve) => {
      const contentForUrl = this.data[url];

      if (contentForUrl) {
        resolve(contentForUrl);
      } else {
        fetch(url, {
          headers: {
            Accept: "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            resolve(res);
          });
      }
    });
  }

  /**
   * @returns {void}
   */
  replacePage() {
    const backgroundMedia = this.state.background_media;

    if (backgroundMedia) {
      const t = setTimeout(() => {
        // If loading the image takes more than 200ms show the spinner
        this.showSpinner();
      }, 200);
      this.prefetchImage(backgroundMedia.url).then(() => {
        clearTimeout(t);
        this.hideSpinner();
      });
    }

    document.title = this.state.title;

    this.replaceContent();
  }

  /**
   * @returns {void}
   */
  replaceContent() {
    this.updateContent();
    this.updateFooterNavigation();
    this.updateHeaderNavigation();
    this.updateArrowNavigation();
  }

  /**
   * @param {object} data
   * @returns {Promise}
   */
  prefetchImage(data) {
    return new Promise((resolve) => {
      const image = new Image();
      const format = this.webpSupported ? "webp" : "jpg";
      const mq = window.matchMedia("(max-width: 88.25rem)");
      let url;

      if (mq.matches) {
        url = `${data}.${format}`;
      } else {
        url = `${data}@2x.${format}`;
      }

      image.onload = () => {
        resolve();
      };

      image.onerror = () => {
        resolve();
      };

      image.src = url;
    });
  }

  /**
   * @returns {void}
   */
  updateContent() {
    const newContent = document.createElement("div");
    newContent.classList.add("DefaultPage-newContent", "is-fadedIn");
    newContent.innerHTML =
      this.state.view ||
      '<div class="DefaultPage-contentInner"><div class="RichText"><p>Something went wrong.</p></div></div>';

    const currentContent = this.elements.contentWrapper.querySelector(
      ".DefaultPage-currentContent"
    );

    this.elements.contentWrapper.appendChild(newContent);

    setTimeout(() => {
      window.requestAnimationFrame(() => {
        currentContent.classList.add("is-fadedOut");
      });
    }, 500);

    const animationEndHandler = () => {
      newContent.classList.remove("DefaultPage-newContent", "is-fadedIn");
      newContent.classList.add("DefaultPage-currentContent");
      currentContent.remove();

      this.prefetchContent().then(() => {
        this.prefetchImage(
          this.data[this.state.navigation_arrows.next_link].background_media.url
        );
      });

      this.isChangingPage = false;
      this.notify({ event: "content-updated" });
      newContent.removeEventListener("animationend", animationEndHandler);
    };

    newContent.addEventListener("animationend", animationEndHandler);
  }

  /**
   * @returns {void}
   */
  updateFooterNavigation() {
    const wrapper = this.elements.defaultPage.querySelector(
      ".DefaultPage-footerNavigation"
    );
    const nav = wrapper.querySelector(".FooterNavigation");

    if (this.state.footer_navigation) {
      if (wrapper.children.length === 0) {
        wrapper.addEventListener("animationend", () => {
          wrapper.classList.remove("is-animated");
        });

        window.requestAnimationFrame(() => {
          wrapper.classList.add("is-animated");
        });
      }

      wrapper.innerHTML = this.state.footer_navigation;

      const indicators =
        this.elements.defaultPage.querySelector(".SubPageIndicators");

      if (
        this.currentFooterNavigationActive !==
          this.state.footer_navigation_active &&
        indicators
      ) {
        indicators.addEventListener("animationend", () => {
          indicators.classList.remove("is-animated");
        });

        indicators.classList.add("is-animated");
      }
    } else if (nav) {
      nav.addEventListener("animationend", () => {
        nav.remove();
        wrapper.classList.remove("is-animated");
      });

      window.requestAnimationFrame(() => {
        nav.classList.add("is-slidedOut");
      });
    }

    this.currentFooterNavigationActive = this.state.footer_navigation_active;
  }

  /**
   * @returns {void}
   */
  updateHeaderNavigation() {
    const activeLink = this.elements.headerNavigation.querySelector(
      'a[aria-current="true"]'
    );

    if (activeLink) {
      activeLink.removeAttribute("aria-current");
    }

    if (this.state.header_navigation_active) {
      const newActiveLink = this.elements.headerNavigation.querySelector(
        `a[href="/${this.state.header_navigation_active}"]`
      );

      if (newActiveLink) {
        newActiveLink.setAttribute("aria-current", "true");
      }
    }
  }

  /**
   * @returns {void}
   */
  updateArrowNavigation() {
    if (this.state.navigation_arrows) {
      if (this.elements.arrows.prev) {
        if (this.elements.arrows.prev.link) {
          this.elements.arrows.prev.link.setAttribute(
            "href",
            this.state.navigation_arrows.prev_link
          );
        }
        if (this.elements.arrows.prev.label) {
          this.elements.arrows.prev.label.textContent =
            this.state.navigation_arrows.prev_label;
        }
      }
      if (this.elements.arrows.next) {
        if (this.elements.arrows.next.link) {
          this.elements.arrows.next.link.setAttribute(
            "href",
            this.state.navigation_arrows.next_link
          );
        }
        if (this.elements.arrows.next.label) {
          this.elements.arrows.next.label.textContent =
            this.state.navigation_arrows.next_label;
        }
      }
    }
  }

  /**
   * @param {HTMLAnchorElement} link
   * @returns {boolean}
   */
  // eslint-disable-next-line consistent-return
  static isInternalLink(link) {
    if (!link.getAttribute("class") === "phpdebugbar-tab") {
      if (link.getAttribute("href").indexOf("http") === 0) return false;
      if (
        link.getAttribute("target") &&
        link.getAttribute("target") === "_blank"
      )
        return false;
      return true;
    }
  }

  /**
   * @param {HTMLElement} element
   * @returns {HTMLElement}
   */
  static getLink(element) {
    return element.tagName === "A" ? element : element.closest("a");
  }

  /**
   * @returns {void}
   */
  showSpinner() {
    this.elements.spinner.classList.add("is-fadedIn");
    this.elements.spinner.setAttribute("aria-hidden", "false");
    const showSpinnerHandler = () => {
      this.elements.spinner.classList.remove("is-fadedIn");
      this.elements.spinner.removeEventListener(
        "animationend",
        showSpinnerHandler
      );
    };
    this.elements.spinner.addEventListener("animationend", showSpinnerHandler);
  }

  /**
   * @returns {void}
   */
  hideSpinner() {
    if (this.elements.spinner.getAttribute("aria-hidden") === "true") return;
    this.elements.spinner.classList.add("is-fadedOut");
    const hideSpinnerHandler = () => {
      this.elements.spinner.classList.remove("is-fadedOut");
      this.elements.spinner.setAttribute("aria-hidden", "true");
      this.elements.spinner.removeEventListener(
        "animationend",
        hideSpinnerHandler
      );
    };
    this.elements.spinner.addEventListener("animationend", hideSpinnerHandler);
  }

  /**
   * # Subscribe method for the Observer pattern #
   * Adds a new listener to the subscribers array
   *
   * @param {object} listener
   */
  subscribe(listener) {
    this.subscribers.push(listener);
  }

  /**
   * # Notify method for the Observer pattern #
   * Notifies all subscribers that a given event happened
   *
   * @param {object} context
   */
  notify(context) {
    this.subscribers.forEach((s) => {
      s.update(context);
    });
  }
}

export default ContentFetching;
