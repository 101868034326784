class ModalButton {
  /**
   *
   */
  constructor() {
    document.addEventListener("click", (e) => this.clickHandler(e));
    window.addEventListener("keydown", (e) => this.keydownHandler(e));
  }

  /**
   * Handles the keydown evebt and checks if should close the modal
   *
   * @param {MouseEvent} e
   */
  keydownHandler(e) {
    const key = e.key || e.keyCode;
    if ((key === "Escape" || key === "Esc" || key === 27) && this.modal) {
      this.closeModal();
    }
  }

  /**
   * Handles the click event and checks if the modal should open or close
   *
   * @param {MouseEvent} e
   */
  clickHandler(e) {
    const { target: triggerEl } = e;
    e.stopPropagation();

    const openTriggers = Array.from(
      document.querySelectorAll("[data-modal-open]")
    );
    const openTrigger = openTriggers.find((trigger) =>
      trigger.contains(triggerEl)
    );

    const closeTriggers = Array.from(
      document.querySelectorAll("[data-modal-close]")
    );
    const closeTrigger = closeTriggers.find((trigger) =>
      trigger.contains(triggerEl)
    );

    const trigger = openTrigger || closeTrigger;
    if (!trigger) return;

    this.modal =
      document.getElementById(trigger.getAttribute("aria-controls")) ||
      trigger.closest(".js-Modal");

    const clickedOnModalContent =
      this.modal &&
      this.modal.querySelector(".js-Modal-content").contains(triggerEl);

    if (openTrigger) {
      this.triggerElement = openTrigger;
      this.showModal();
    } else if (closeTrigger && !clickedOnModalContent) {
      this.closeModal();
    }
  }

  /**
   * Opens the modal with the target video and starts playing in mobile viewports
   */
  showModal() {
    document.body.classList.add("is-hidden-navigation");

    if (this.triggerElement && this.modal) {
      this.modal.setAttribute("aria-hidden", false);
      this.modal.classList.add("is-open");
      document.querySelector(".Modal-overlay").focus();
    }

    this.onOpen();
  }

  /**
   *
   */
  closeModal() {
    document.body.classList.remove("is-hidden-navigation");

    this.onClose();

    if (this.modal) {
      this.modal.setAttribute("aria-hidden", true);
      const closeModalhandler = () => {
        this.modal.classList.remove("is-open");

        if (this.triggerElement) {
          this.triggerElement.focus();
          this.triggerElement = null;
        }

        this.modal.removeEventListener("animationend", closeModalhandler);
      };

      this.modal.addEventListener("animationend", closeModalhandler);
    }
  }

  /**
   * Attaches a callback to be called when opening the modal
   *
   * @param {Function} callback
   * @returns {self}
   */
  onOpen(callback) {
    this.onOpen = callback;

    return this;
  }

  /**
   * Attaches a callback to be called when closing the modal
   *
   * @param {Function} callback
   * @returns {self}
   */
  onClose(callback) {
    this.onClose = callback;

    return this;
  }
}

export default ModalButton;
