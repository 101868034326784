class Legal {
  /**
   * @param {Element} element
   */
  constructor(element) {
    this.legal = element;
    this.openTrigger = this.legal.querySelector(".js-Legal-openButton");
    this.closeTrigger = this.legal.querySelector(".js-Legal-closeButton");
    this.overlay = this.legal.querySelector(".js-Legal-overlay");

    this.openTrigger.addEventListener("click", (e) => this.expandLegalNav(e));
    this.closeTrigger.addEventListener(
      "click",
      this.collapseLegalNav.bind(this)
    );
    this.overlay.addEventListener("click", this.collapseLegalNav.bind(this));

    window.addEventListener("load", () => {
      this.updateButtonWidthCustomProperty();
    });

    window.addEventListener("keydown", this.keydownHandler.bind(this));
  }

  /**
   * Handles the keydown event and closes the navigation list
   * if Esc is pressed
   *
   * @param {KeyboardEvent} e
   */
  keydownHandler(e) {
    const key = e.key || e.keyCode;
    if (key === "Escape" || key === "Esc" || key === 27) {
      if (this.legal.classList.contains("is-expanded")) {
        this.collapseLegalNav();
      }
    }
  }

  /**
   * Updates the --Legal-list-buttonWidth custom property with
   * the computed first item width
   */
  updateButtonWidthCustomProperty() {
    const computedWidth = `${
      this.openTrigger.parentElement.offsetWidth / 10
    }rem`;
    let currentWidth = 0;

    const customPropertyValue = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--Legal-list-buttonWidth");

    if (customPropertyValue) {
      currentWidth = customPropertyValue.trim();
    }

    if (currentWidth !== computedWidth) {
      document.documentElement.style.setProperty(
        "--Legal-list-ButtonWidth",
        computedWidth
      );
    }
  }

  /**
   * Expands the legal navigation list
   *
   * @param {MouseEvent} e
   */
  expandLegalNav(e) {
    e.stopPropagation();
    this.legal.classList.add("is-transitioning");
    this.legal.classList.add("is-expanded");
    this.openTrigger.setAttribute("aria-expanded", true);
    this.closeTrigger.setAttribute("aria-expanded", true);
  }

  /**
   * Collapses the legal navigation list
   */
  collapseLegalNav() {
    if (this.legal.classList.contains("is-expanded")) {
      const transitionEndHandler = () => {
        this.legal.classList.remove("is-transitioning");
        this.legal.removeEventListener("transitionend", transitionEndHandler);
      };

      this.legal.classList.remove("is-expanded");
      this.legal.addEventListener("transitionend", transitionEndHandler);
      this.openTrigger.setAttribute("aria-expanded", false);
      this.closeTrigger.setAttribute("aria-expanded", false);
    }
  }
}

export default Legal;
