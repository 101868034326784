class MobileLegal {
  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    if (!element) return;
    this.mobileLegalButton = element;
    const targets = this.mobileLegalButton.getAttribute("aria-controls");

    const [target, legalMenu] = targets.split(" ");
    if (!target || !legalMenu) return;
    this.legalNav = document.getElementById(legalMenu);
    this.regularNav = document.getElementById(target);

    this.mobileLegalButton.addEventListener(
      "click",
      this.clickHandler.bind(this)
    );
  }

  /**
   * Handles the click event on the mobile legal button
   */
  clickHandler() {
    const legalMenuIsOpen =
      this.mobileLegalButton.getAttribute("aria-expanded") === "true";

    if (!legalMenuIsOpen) {
      this.showLegalNav();
      this.mobileLegalButton.setAttribute("aria-expanded", "true");
    } else {
      this.showRegularNav();
      this.mobileLegalButton.setAttribute("aria-expanded", "false");
    }
  }

  /**
   * Fades out the regular nav and shows the legal nav
   */
  showLegalNav() {
    const animationHandler = () => {
      this.regularNav.toggleAttribute("hidden", true);
      this.regularNav.classList.remove("is-fadedOut");
      this.legalNav.classList.add("is-fadedIn");
      this.legalNav.toggleAttribute("hidden", false);
      this.regularNav.removeEventListener("animationend", animationHandler);
    };

    this.regularNav.classList.add("is-fadedOut");
    this.regularNav.addEventListener("animationend", animationHandler);
  }

  /**
   * Fades out the legal nav and shows the regular nav
   */
  showRegularNav() {
    const animationHandler = () => {
      this.legalNav.toggleAttribute("hidden", true);
      this.legalNav.classList.remove("is-fadedOut");
      this.regularNav.classList.add("is-fadedIn");
      this.regularNav.toggleAttribute("hidden", false);
      this.legalNav.removeEventListener("animationend", animationHandler);
    };

    this.legalNav.classList.add("is-fadedOut");
    this.legalNav.addEventListener("animationend", animationHandler);
  }
}

export default MobileLegal;
