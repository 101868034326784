/**
 * @param {object} context
 */
class FetchBrightcove {
  /**
   * Objects that subscribe to events fired by this class
   */
  constructor() {
    this.subscribers = [];
  }

  /**
   * Adds event listeners to fetch BC script to any element that matches selector
   */
  init() {
    Array.from(document.querySelectorAll(".js-FetchBrightcove")).forEach(
      (el) => {
        el.addEventListener("click", this.fetch.bind(this));
        el.addEventListener("mouseover", this.fetch.bind(this));
        el.addEventListener("focus", this.fetch.bind(this));
      }
    );
  }

  /**
   * @param {object} context
   */
  update(context) {
    if (context.event && context.event === "content-updated") {
      this.init();
    }
  }

  /**
   * @param {object} subscriber
   */
  subscribe(subscriber) {
    this.subscribers.push(subscriber);
  }

  /**
   * @param {object} event
   */
  notify(event) {
    this.subscribers.forEach((subscriber) => {
      subscriber.update(event);
    });
  }

  /**
   * @returns {void}
   */
  fetch() {
    if (!document.getElementById("brightcoveScript")) {
      const brightcoveScript = document.createElement("script");
      brightcoveScript.src = window.brightcove_player_url;
      brightcoveScript.id = "brightcoveScript";
      brightcoveScript.onload = () => {
        this.notify({ event: "bc-fetched" });
      };
      document.body.appendChild(brightcoveScript);
    }
  }
}

export default FetchBrightcove;
