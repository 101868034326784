class Swipe {
  /**
   * @param {Element} element
   */
  constructor(element) {
    this.xDown = null;
    this.yDown = null;
    this.element =
      typeof element === "string" ? document.querySelector(element) : element;

    this.element.addEventListener(
      "touchstart",
      (evt) => {
        this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
      },
      false
    );

    this.element.addEventListener(
      "touchmove",
      (evt) => {
        this.handleTouchMove(evt);
      },
      false
    );
  }

  /**
   * @param {Function} callback
   * @returns {self}
   */
  onLeft(callback) {
    this.onLeft = callback;

    return this;
  }

  /**
   * @param {Function} callback
   * @returns {self}
   */
  onRight(callback) {
    this.onRight = callback;

    return this;
  }

  /**
   * @param {Function} callback
   * @returns {self}
   */
  onUp(callback) {
    this.onUp = callback;

    return this;
  }

  /**
   * @param {Function} callback
   * @returns {self}
   */
  onDown(callback) {
    this.onDown = callback;

    return this;
  }

  /**
   * @param {TouchEvent} evt
   */
  handleTouchMove(evt) {
    if (!this.xDown || !this.yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    this.xDiff = this.xDown - xUp;
    this.yDiff = this.yDown - yUp;

    if (Math.abs(this.xDiff) > Math.abs(this.yDiff)) {
      if (this.xDiff > 0) {
        this.onLeft();
      } else {
        this.onRight();
      }
    } else if (this.yDiff > 0) {
      this.onUp();
    } else {
      this.onDown();
    }

    this.xDown = null;
    this.yDown = null;
  }
}

export default Swipe;
